<template>
  <main-layout :title="title" :is-loading="isLoading">
    <first-step-registration-reenter
      @setTitle="setTitle"
      @startLoading="startLoading"
      @endLoading="endLoading"
    >
    </first-step-registration-reenter>
  </main-layout>
</template>

<script>
  import MainLayout from '../components/layout/MainLayout'
  import FirstStepRegistrationReenter from '../components/registration-reenter/first-step/index.vue'
  export default {
    name: 'RegistrationReenter',
    components: { FirstStepRegistrationReenter, MainLayout },
    data() {
      return {
        isLoading: false,
        title: "アカウント登録 | Svenson"
      }
    },
    methods: {
    setTitle(title) {
      this.title = title
    },
    startLoading() {
      this.isLoading = true
    },
    endLoading() {
      this.isLoading = false
    },
  }
  }
</script>

<style scoped>

</style>

